import * as React from "react";
import { Helmet } from 'react-helmet';
import '../assets/css/pages/apps-page.css';
import NewsItem from "../components/NewsItem";
import Footer from "../components/Footer";
import Header, { CurrentPageType } from "../components/Header";
import NewsData from '../data/newsData.json';
import ReactGA from 'react-ga';

const NewsPage = () => {
  const renderNews = () => {
    const newsItems = NewsData.news.map(({id, title, timestamp, mainImageName, description }) => {
      return (
        <NewsItem key={id} id={id} title={title} timestamp={timestamp} mainImageName={mainImageName} description={description}/>
      );
    });
    return(
      <React.Fragment>
        {newsItems}
      </React.Fragment>
    )
  }

  React.useEffect(() => {
  	ReactGA.initialize('UA-88504612-1');
  	ReactGA.pageview("/news");
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Pixel Works Software: News</title>
      </Helmet>
      <div className="gradient"></div>
      <Header currentPage={CurrentPageType.NEWS} />
      <main>
        {renderNews()}
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default NewsPage;