import React from 'react';
import { Link } from 'gatsby';
import '../assets/css/components/news-item.css';
import { NewsItemProps } from '../types/newsItem';

const NewsItem: React.FC<NewsItemProps> = ({id, title, timestamp, description }) => {
    return (
      <div className="news-item">
        <div className="news-item__top">
          <h4 className="news-item__name">{title}</h4>
          <p className="news-item__timestamp">{timestamp}</p>
        </div>
        <div className="news-item__description-container">
          <p className="news-item__description">{description}</p>
        </div>
        <Link className="news-item__read-more" to={`/news/${id}`}>Read More</Link>
      </div>
    );
}

export default NewsItem;